import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer>
    <div className='container'>
      <div className='row'>
        <div className='col-md-8'>
          <h3>GET IN TOUCH</h3>
          <br/>
          <p>Talent Appearance: <a href="#">Email</a></p>
          <p>Titleholder and Brand Partnerships: <a href="#">Email</a></p>
          <p>Comments or questions: <a href="#">Click Here</a></p>
          <p>Travel Information: <a href="#">Click Here</a></p>
          <p>Broadcast Licensing: <a href="#">Click Here</a></p>
        </div>
      
        <div className='col-md-4 social-footer'>
          <h3>FOLLOW US</h3>
          <ul>
            <li><a href='#'><img src='/img/social/fb.png'></img></a></li>
            <li><a href='#'><img src='/img/social/twitter.png'></img></a></li>
            <li><a href='#'><img src='/img/social/insta.png'></img></a></li>
            <li><a href='#'><img src='/img/social/yt.png'></img></a></li>
            <li><a href='#'><img src='/img/social/tiktok.png'></img></a></li>
            <li><a href='#'><img src='/img/social/mail.png'></img></a></li>
            


          </ul>
          <br/>
          
          <img className='footerlogo' src="/img/misslogo.png" alt="Miss Universe Logo"></img>
        </div>
      </div>
    </div>
    <div className='footer-bottom'>
    
        <div className='links'>
        
          <div className='col-md-6'>
        
              <a href='#'>Terms of Use</a>
              <a href='#'>Privacy Policy</a>
              <a href='#'>Cookie Policy</a>
          
          </div>
        </div>
     
    </div>
    <div className='footer-copyright'>
    <p>© 2023 JKN UNIVERSE LLC. All Rights Reserved</p>
    </div>
  </footer>
);

export default Footer;
