import React from 'react';
import './About.css';
const About = () => {
  return (
    <div className='about-us'>
      <div className='container'>

      <center><svg preserveAspectRatio="xMidYMid meet" data-bbox="0.96 0.437 62.08 59.239" xmlns="http://www.w3.org/2000/svg" viewBox="0.96 0.437 62.08 59.239" height="60" width="64" data-type="color" role="presentation" aria-hidden="true">
    <g>
        <path fill="#ffffff" d="m63.04 30.057-26.18-2.383 10.074-11.7-.022-.02-12.261 9.655L32.111.437h-.045l-2.54 25.13-12.262-9.635-.044.021 10.14 11.7-26.4 2.404v.042l26.378 2.424-10.14 11.721.022.043 12.306-9.719 2.54 25.108h.044l2.541-25.023 12.24 9.634h.021L36.75 32.523 63.04 30.1v-.042Z" data-color="1"></path>
    </g>
  </svg></center>
      <h1 className='about-title'>About Miss Universe</h1>
     
          <div className='row'>
            <div className='col-md-6' style={{alignContent: 'center'}}>
              
              <img src='/img/72-nd.png'></img>
              </div>
              <div className='col-md-6' style={{alignContent: 'center'}}>
                <p>
                The Miss Universe Organization (MUO) is a global, inclusive organization that celebrates all cultures, backgrounds and religions. We create and provide a safe space for women to share their stories and drive impact personally, professionally, and philanthropically. The women who participate in this international platform serve as inspirational leaders and role models to their communities and fans around the world.
                </p>
                <p>
              
                The Miss Universe Organization exists to advocate for a future forged by women - women with the
                courage to push the limits of what’s possible, who are curious enough to make world-shaking discoveries,
                and audacious enough to do this over and over. We believe that the future of humanity rests on reaching
                gender equity around the world. 
                </p>
                <p>
                MUO empowers women to realize their ambition and build self-confidence as a catalyst for future
success. MUO delegates and titleholders are able to cultivate their personal, professional, and
philanthropic goals. These women are forward-thinking and motivated not just to talk about change, but to
enact it.
                </p>
                <p>
                Research has shown a lack of self-confidence is the #1 obstacle for women to overcome in trying to
reach their potential. By developing self-confidence through MUO experiences, women have gone on to
high-profile careers in government, business, finance, broadcasting, and entertainment.
                </p>
                <button className='btn-about'>About Miss Universe</button>
              </div>
          </div>

          <div className='row' style={{paddingTop: '40px'}}>
            <div className='col-md-6' style={{alignContent: 'center'}}>
            The finals of the 72nd Miss Universe Pageant was held in magical El Salvador on 18th November, 2023. 84 Miss Universe delegates vied for the prestigious “Force for Good” crown. 

            ​<br/><br/>
            <p>With over 25 years of international experience in business development, trade, event production, and
            beauty pageants, and as the exclusive holders of the MISS UNIVERSE license in Romania, we at
            <a href="https://royalbusinesscorporation.com)">ROYAL BUSINESS CORPORATION</a> are dedicated
            to upholding the prestigious principles and values established by the Miss Universe Organization.
            Our goal every year is to select, prepare and delegate every year, the representative of Romania in MISS
            UNIVERSE.</p>
            <p>
            We strive to promote women's empowerment, diversity, and excellence in every aspect of our work,
            ensuring that everything we undertake resonates with the spirit of global unity and beauty that defines
            Miss Universe. At the national level, we always apply the international format, rules, values and principles
            of MISS UNIVERSE, while respecting national laws, traditions, religion and culture, which may differ from
            country to country. This approach ensures a harmonious blend between global standards and local
            uniqueness.
            </p>
            <p>
            Miss Universe Romania transcends traditional beauty pageants, serving as a platform for promotion,
            cultural exchange, personal growth, and charitable activities by partnering with the Children's Charity
            Foundation to support children with cancer. Our contestants become ambassadors for social issues,
            cultural diversity and humanitarian causes. They go through personal development, acquiring skills in
            leadership, public speaking and self-confidence. By engaging in charitable activities, they contribute to
            global efforts for positive change on all social and political levels. We foster a community of strong
            women, encouraging collaboration and networking globally. Through MISS UNIVERSE’s multi-purpose
            approach, it emphasizes the importance of intelligence, social awareness and concrete contributions with
            social impact, above physical appearance.
            </p>
            <p>
            Through the Miss Universe Romania competition, we will direct the public's attention to new life models –
            namely the Miss Universe Romania crown holder, who must be not only a beautiful woman, but her
            beauty must be complemented by intelligence, refinement, elegance, creativity, communication skills and
            very well-defined moral values. A woman involved in charitable and social projects, selfless, empathetic
            and dedicated, a patriot with a strong commitment to the community, with a spirit of volunteerism and

            generosity. The winner of Miss Universe Romania must be inspirational, having a positive impact on
            those around her and being a model of active involvement in changing and improving society.
            </p>
            <p>
            Through the Miss Universe Romania competition, we aim to draw the public's attention to new role
            models - specifically, the Miss Universe Romania crown holder, a woman who should not only possess
            beauty, but her beauty must be complemented by intelligence, refinement, elegance, creativity,
            communication skills, and strong moral values. She should be actively involved in charitable and social
            projects, demonstrating selflessness, empathy, dedication, and patriotism, with a deep commitment to her
            community, a spirit of volunteerism, and generosity. The winner of Miss Universe Romania must be an
            inspirational figure, positively impacting those around her and serving as a model of active involvement in
            the betterment of society.
            </p>

            <br/><br/>
            <button className='btn-about'>About Miss Universe Romania</button>
              </div>
              <div className='col-md-6' style={{alignContent: 'center'}}>
                <p>
                <img src='/img/el-salvador.png'></img>
                </p>
               
              </div>
          </div>

          <div className='row'>
            <div className='col-md-6' style={{alignContent: 'center'}}>
              
              <img src='/img/72-nd.png'></img>
              </div>
              <div className='col-md-6' style={{alignContent: 'center'}}>
                <p>
                Discover Romania: A Hidden Gem of Eastern Europe
Romania is a captivating country nestled in Eastern Europe, known for its rich history, stunning natural
landscapes, and vibrant culture. If you’re looking for a destination that offers a unique blend of medieval
charm, modern energy, and unspoiled nature, Romania is the perfect place to visit. Here are some of the
most compelling reasons to explore this hidden gem.</p>
                <p>
                1. Breathtaking Natural Landscapes
Romania boasts a diverse range of natural beauty that will leave any traveler in awe. The Carpathian
Mountains, which arc across the country, provide excellent opportunities for hiking, skiing, and wildlife
spotting. These mountains are home to one of the largest undisturbed forests in Europe and a rich array
of wildlife, including bears, wolves, and lynxes.
In addition to the mountains, the Danube Delta, a UNESCO World Heritage site, is a paradise for
birdwatchers and nature lovers. It is one of the most biodiverse regions in the world, with over 300
species of birds and numerous fish and plant species. The delta’s labyrinthine network of waterways and
lakes makes it a unique and tranquil escape into nature.
                </p>
                <p>
                2. Rich History and Culture
Romania’s history is as rich and varied as its landscapes. The country is dotted with medieval castles,
fortified churches, and ancient monasteries that transport visitors back in time. Perhaps the most famous
of these is Bran Castle, often referred to as "Dracula's Castle." This iconic landmark is shrouded in myth
and legend, adding an element of mystery and intrigue to any visit.
Another historical highlight is Sighișoara, a perfectly preserved medieval town that is also a UNESCO
World Heritage site. Walking through its cobbled streets and admiring the colorful houses, towers, and
fortified walls feels like stepping into a fairy tale.
</p>
                <p>
                3. Vibrant Cities with Modern Flair
Romania’s cities offer a vibrant mix of old-world charm and contemporary life. Bucharest, the capital, is a
bustling metropolis where history meets modernity. Visitors can explore a range of attractions from the
colossal Palace of the Parliament—the second largest administrative building in the world—to the
charming streets of the Old Town, filled with lively cafes, restaurants, and bars.

Cluj-Napoca, a city in the heart of Transylvania, is known for its youthful energy and cultural events. It’s a
hub for music festivals, art, and nightlife, making it a perfect destination for those looking to experience
Romania's dynamic cultural scene.
</p>
<p>
4. Delicious Cuisine and Warm Hospitality
Romanian cuisine is a delightful blend of flavors influenced by its diverse history. Traditional dishes like
sarmale (cabbage rolls filled with meat and rice), mici (grilled sausages), and ciorbă de burtă (tripe
soup) are must-tries for food enthusiasts. The country is also known for its excellent wines and the
famous țuică, a strong plum brandy that’s often enjoyed before a meal.
Beyond the food, Romanians are known for their warmth and hospitality. Whether you’re exploring a
bustling city or a quaint village, you’ll find friendly locals eager to share their culture, traditions, and stories
with visitors.
</p>
<p>
5. Affordable and Accessible
One of the best things about Romania is that it offers great value for money. Accommodation, dining, and
activities are generally more affordable compared to other European destinations, making it an excellent
choice for budget-conscious travelers. The country is also well-connected with major European cities,
making it easy to reach by air, train, or car.
</p>
<p>
6. A Blend of Traditions and Modernity
Romania is a land where ancient traditions coexist with modern influences. In many rural areas,
traditional crafts, music, and dances are still very much alive, offering a glimpse into the country’s folk
heritage. Yet, at the same time, Romania is a forward-looking nation with a growing economy and a
thriving tech industry, especially in cities like Bucharest and Cluj-Napoca.

</p>
<p>
Conclusion
Romania is a country that surprises and delights at every turn. Its diverse landscapes, rich history, vibrant
culture, and warm hospitality make it a destination that caters to all kinds of travelers. Whether you are an
adventurer, a history buff, a foodie, or someone looking to experience something new and authentic,
Romania has something special to offer. So why not add Romania to your travel list and discover this
beautiful country for yourself?</p>
<button className='btn-about'>About Romania</button>
              </div>
          </div>
        </div>
    </div>
  );
}

export default About;
