import React, { useState } from 'react';
import './Slider.css';  // Make sure to create this CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="slider-container">
      <div className="slider-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="slide" key={index} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
            <div className="slide-content">
            <div className="row">
                {slide.image ? (
                  <div className="col-4">
                    <img src={slide.image} alt={slide.title} />
                  </div>
                ) : null}

                <div className={slide.image ? "col-8" : "col-12"} style={{ alignContent: 'center' }}>
                  <h3>{slide.title}</h3>
                  <p>{slide.text}</p>
                  <button>
                    {slide.buttonText} <FontAwesomeIcon icon={faChevronRight} color='#B49B57' className="fa-icon" />
                  </button>
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>
      <button className="prev" onClick={goToPrevious}><FontAwesomeIcon icon={faChevronLeft} /></button>
      <button className="next" onClick={goToNext}><FontAwesomeIcon icon={faChevronRight}/></button>
    </div>
  );
};

export default Slider;
