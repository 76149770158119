import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';

import './form.css'; // Add your CSS file

const Form = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    dateOfBirth: '',
    placeOfBirth: '',
    citizenship: '',
    address: '',
    mobilePhone: '',
    whatsappNumber: '',
    telegramnumber: '',
    telegramnumberoption: 'No',
    secondaryPhoneOption: 'No',
    secondaryPhone: '',
    height: '',
    weight: '',
    bustwaisthips: '',
    eyeColor: '',
    hairColor: '',
    email: '',
    instagramOption: 'No',
    instagramHandle: '',
    facebookOption: 'No',
    facebookHandle: '',
    plasticsurgeries: '',
    tattoos: '',
    languages: '',
    relationshipstatus: 'Single',
    mother: 'No',
    hobbies: '',
    talents: '',
    musicgenre: '',
    singers: '',
    moviegenre: '',
    books: '',
    people: '',
    occupation: '',
    contract: '',
    activities: '',
    pageants: '',
    future: '',
    topless: '',
    criminal: '',
    participate: '',
    qualities: '',
    details: '',
    photo1: '',
    photo2: ''
  });
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [cofirmationError, setConfirmationError] = useState('');

  const nextStep = () => {
    setStep(step + 1);
    scrollToTop();
  };

  const prevStep = () => {
    setStep(step - 1);
    scrollToTop();
  };

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: document.querySelector('.form-container').offsetTop,
      behavior: 'smooth',
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

  // Create a FormData object to send files and form data
  const formDataToSend = new FormData();

  // Append form data to the FormData object
  Object.keys(formData).forEach((key) => {
    formDataToSend.append(key, formData[key]);
  });

  // Append files to the FormData object
  if (formData.photo1) {
    formDataToSend.append('photo1', formData.photo1);
  }
  if (formData.photo2) {
    formDataToSend.append('photo2', formData.photo2);
  }

  // Send form data to the server using fetch
  fetch('https://apimiss.flum.ro:8083/submit-form', {
    method: 'POST',
    body: formDataToSend, // Send FormData as the request body
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
      setConfirmationMessage('Your form has been submitted successfully');
      setConfirmationError('No');
    })
    .catch((error) => {
      console.error('Error:', error);
      setConfirmationMessage('An error occurred');
      setConfirmationError('Yes');
    });

    // Move to confirmation step
    setStep(7);
  };

  return (
    <div>
    <div className="form-container">
      {step !== 7 && (<div> <h1>Step {step} of 6</h1><div className="progress-bar">
        <div style={{ width: `${(step / 6) * 100}%` }}></div>
      </div></div>
      )}
     
      

      {step === 1 && (
        <Step1 nextStep={nextStep} handleChange={handleChange} formData={formData} />
      )}
      {step === 2 && (
        <Step2 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />
      )}
       {step === 3 && (
        <Step3 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />
      )}
      {step === 4 && (
        <Step4 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />
      )}
    {step === 5 && (
        <Step5 nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />
      )}
      {step === 6 && (
          <Step6 prevStep={prevStep} handleSubmit={handleSubmit} handleChange={handleChange} formData={formData} setFormData={setFormData}/>
        )}
        {step === 7 && (
          <Step7 confirmationMessage={confirmationMessage} confirmationError={cofirmationError} prevStep={prevStep}/>
        )}
    </div>
      <div className='footer-banner'>
            <img src='/img/formbanner.png' alt='Miss Universe'></img>
        </div>
    </div>
  );
};

export default Form;
