import React from 'react';

const Step2 = ({ nextStep, prevStep, handleChange, formData }) => {
  return (
    <div style={{paddingTop: '40px', paddingBottom: '40px'}}>
      <h2>Basic Information</h2>
      <label>Name and Surname</label>
      <input
        type="text"
        value={formData.name}
        onChange={handleChange('name')}
        required
      />
      <label>Age</label>
      <input
        type="text"
        value={formData.age}
        onChange={handleChange('age')}
        required
      />
      <label>Date of Birth</label>
      <input
        type="date"  // Change from 'text' to 'date'
        value={formData.dateOfBirth}
        onChange={handleChange('dateOfBirth')}
        required
      />

      <label>Place of Birth</label>
      <input
        type="text"
        value={formData.placeOfBirth}
        onChange={handleChange('placeOfBirth')}
        required
      />
      <label>Citizenship(s)</label>
      <input
        type="text"
        value={formData.citizenship}
        onChange={handleChange('citizenship')}
        required
      />
       <label>Address (Residence)</label>
      <input
        type="text"
        value={formData.address}
        onChange={handleChange('address')}
        required
      />
       <button className='formprev' onClick={prevStep}>Back</button>
       <button className='formnext' onClick={nextStep}>Next</button>
    </div>
  );
};

export default Step2;
