import React from 'react';

const Step6 = ({ prevStep,handleSubmit, setFormData, handleChange, formData }) => {
  const handleFileChange = (input) => (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      [input]: file,
    }));
  };

  return (
    <div style={{paddingTop: '40px', paddingBottom: '40px'}}>
      <h2>Upload 2 photos</h2>
      
      <label>Upload first photo</label>
      <input
        type="file"
        onChange={handleFileChange('photo1')} // handle file change
        accept="image/*" // restrict to image files
        required
      />
        <label>Upload second photo</label>
        <input
        type="file"
        onChange={handleFileChange('photo2')} // handle file change
        accept="image/*"
        required
      />


      <button className='formprev' onClick={prevStep}>Back</button>
      <button className='formnext' onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default Step6;
