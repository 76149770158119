import React from 'react';

const Step4 = ({ prevStep,nextStep, handleChange, formData }) => {
  return (
    <div style={{paddingTop: '40px', paddingBottom: '40px'}}>
      <h2>Physical Characteristics</h2>
      <label>Height (cm)</label>
      <input
        type="text"
        value={formData.height}
        onChange={handleChange('height')}
        required
      />
      <label>Weight (kg)</label>
      <input
        type="text"
        value={formData.weight}
        onChange={handleChange('weight')}
        required
      />
      <label>Bust - Waist - Hips (cm)</label>
      <input
        type="text"
        value={formData.bustwaisthips}
        onChange={handleChange('bustwaisthips')}
        required
      />
      <label>Eye Color</label>
      <input
        type="text"
        value={formData.eyeColor}
        onChange={handleChange('eyeColor')}
        required
      />
      <label>Hair Color</label>
      <input
        type="text"
        value={formData.hairColor}
        onChange={handleChange('hairColor')}
        required
      />
     

      <button className='formprev' onClick={prevStep}>Back</button>
      <button className='formnext' onClick={nextStep}>Next</button>
    </div>
  );
};

export default Step4;
